/* purgecss ignore */

.footer {
  color: #6d6d6d;
}

.footer-light {
  color: #b1b1b1;
}

.bg-footer {
  background-color: #efefef;
}

.footer .color-line::before {
  content: "";
  background: url("../images/png/footer-line.png");
  height: 5px;
  width: 100%;
  display: block;
}

.footer .highlight {
  font-size: 20px;
  color: #00518c;
}

.footer .social li {
  margin-right: 20px;
}
