/* purgecss ignore */

.konami {
  position: absolute;
  top: 50%;
  left: -550px;
  background: url("../images/gif/17xo.gif");
  width: 500px;
  height: 198px;
  z-index: 9000; /* It's over 9000 !!! */
  animation: 3s linear 0s slide;
}
.deg {
  transform: rotate(45deg);
}

@keyframes slide {
  from {
    left: -550px;
  }
  to {
    left: 200%;
    transform: translateX(-200%);
  }
}
.image-footer {
  background: #00528a;
  text-align: center;
  display: table-cell;
  border-radius: 50%;
  -webkit-justify-content: center;
  margin-left: 0%;
  width: 70px;
  height: 70px;
  float: left;
}
.text-center {
  text-align: center;
}
.a-image-footer {
  text-align: center;
  -webkit-justify-content: center;
}
.img-f {
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* text-align: center; */
  width: 47%;
  height: auto;
  align-content: center;
  text-align: center;
  margin-top: 24%;
}
.item-titre {
  margin: auto;
  margin-top: 25px;
  margin-left: 4px;
  float: left;
  color: #00528a;
}

.a-image-footer {
  text-align: center;
  -webkit-justify-content: center;
}

.block-offring {
  height: auto;
  float: left;
  width: 100%;
  margin-top: 0px;
}

.item-footer {
  float: left;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
}
.bloc-quick {
  margin-top: 10px;
}

.text-bleu {
  color: #00528a;
}
.text-bleue {
  color: #1280bf;
}

.max-with-content {
  max-width: 63%;
}

.pricing-content a {
  color: #2b6cb0;
  text-decoration: underline;
}
