/* purgecss ignore */

.default-input {
  @apply block text-neutral-6 font-bold mb-2 w-full;
}

/* The switchButton - the box around the slider */
.NJ-switchButton {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
}

/* Hide default HTML checkbox */
.NJ-switchButton input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.NJ-switchButton .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #607a8c;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.NJ-switchButton .slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.NJ-switchButton input:checked + .slider {
  background-color: #007acd;
}

.NJ-switchButton input:focus + .slider {
  box-shadow: 0 0 1px #007acd;
}

.NJ-switchButton input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.NJ-switchButton .slider.round {
  border-radius: 34px;
}

.NJ-switchButton .slider.round:before {
  border-radius: 50%;
}
