/* purgecss ignore */
@media (min-width: 576px) {
  .circle-before:before {
    --circleSize: 12px;
    content: "";
    @apply block absolute rounded-full bg-neutral-1 border-2 border-neutral-2;
    width: var(--circleSize);
    height: var(--circleSize);
    top: 50%;
    right: 100%;
    transform: translateY(-50%) translateX(calc((var(--circleSize) - 2px) / 2));
  }

  .triangle-border {
    --triangleSize: 9px;
    content: "";
    @apply absolute;
    top: 50%;
    right: 100%;
    border: var(--triangleSize) solid transparent;
    margin-top: calc(var(--triangleSize) * -1);
    border-right-color: hsl(211, 10%, 53%);
  }
}
